<template>
    <div>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0 d-flex justify-space-between">
                    <h2 class="font-weight-regular">Today's Activation </h2>
                    <v-tooltip bottom><template v-slot:activator="{ on, attrs }"><v-btn icon v-bind="attrs" v-on="on" :loading="loading" @click="get"><v-icon>mdi-refresh</v-icon></v-btn></template><span>Refresh</span></v-tooltip>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="projects.length === 0">
                    <p class="mb-0">No activation today</p>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="projects.length > 0">
                    <v-sheet color="white" elevation="0">
                        <v-tabs show-arrows color="primary" v-model="tab">
                            <v-tab :key="index" v-text="getProjectText(item)" v-for="(item, index) in projects"></v-tab>
                        </v-tabs>
                        <v-toolbar dense class="my-3 pa-0 elevation-0">
                            <v-btn small color="primary" class="elevation-0" :disabled="items.length === 0" :ripple="false" @click="openAttendanceDialog">View Summary</v-btn>
                            <v-spacer></v-spacer>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn small color="primary" class="elevation-0" :disabled="items.length === 0" :ripple="false" v-bind="attrs" v-on="on" >Download Report</v-btn>
                                </template>
                                 <v-list>
                                    <v-list-item @click="openDownloadDialog">
                                        <v-list-item-title>Download Attendance Photo Report</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-toolbar>
                        <v-data-table class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
                            <template v-slot:item='{ item, index }'>
                                <tr>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0">
                                        <v-icon class="pointer elevation-0" :ripple="false" @click="openInfoDialog(item)">mdi-information-outline</v-icon>
                                    </td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="item.campaign.text"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="item.outlet"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-html="getPromoterText(item)"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="getShiftTimeText(item)"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="item.break_time"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0">
                                        <v-chip label small color="light-green white--text" class="my-1" v-if="item.activities.ci && !item.activities.bi">CI: &nbsp;&nbsp;<span v-text="item.activities.ci"></span></v-chip>
                                        <v-chip label small color="light-green white--text" class="my-1" v-if="item.activities.bi && !item.activities.bo">BI: &nbsp;&nbsp;<span v-text="item.activities.bi"></span></v-chip>
                                        <v-chip label small color="light-green white--text" class="my-1" v-if="item.activities.bo && !item.activities.co">BO: &nbsp;&nbsp;<span v-text="item.activities.bo"></span></v-chip>
                                        <v-chip label small color="light-green white--text" class="my-1" v-if="item.activities.co">CO: &nbsp;&nbsp;<span v-text="item.activities.co"></span></v-chip>
                                    </td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0">
                                        <v-chip label small :color="item.status.color"><span v-text="item.status.text"></span></v-chip>
                                    </td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="item.surveys.booth_location_feedback ? item.surveys.booth_location_status + ' - ' + item.surveys.booth_location_feedback :'N/A'"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="item.surveys.is_competitor"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="item.surveys.crowd_flow_before_break"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="item.surveys.crowd_flow_after_break"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0">
                                        <v-btn x-small :ripple="false" color="primary" class="elevation-0" @click="openStatusDialog(item, index, 'ci')" v-if="item.can_ci">Help Check-in</v-btn>
                                        <v-btn x-small :ripple="false" color="primary" class="elevation-0" @click="openStatusDialog(item, index, 'bi')" v-if="item.can_bi">Help Break-in</v-btn>
                                        <v-btn x-small :ripple="false" color="primary" class="elevation-0" @click="openStatusDialog(item, index, 'bo')" v-if="item.can_bo">Help Break-out</v-btn>
                                        <v-btn x-small :ripple="false" color="primary" class="elevation-0" @click="openStatusDialog(item, index, 'co')" v-if="item.can_co">Help Check-out</v-btn>
                                        <v-btn x-small :ripple="false" color="primary" class="elevation-0" @click="salesReportRemind(item.promoter.mobile_number)" v-if="!item.activities.is_sales_submitted">Sales Report Remind</v-btn>
                                        <v-btn x-small :ripple="false" color="primary" class="elevation-0" @click="chatWithPromoter(item)">Chat with promoter</v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-sheet>
                </v-col>
            </v-row>
        </template>
        <status-dialog ref="statusDialog" @updated="updateItem"></status-dialog>
        <attendance-dialog ref="attendanceDialog" @copied="openNotifier"></attendance-dialog>
        <download-dialog ref="downloadDialog"></download-dialog>
        <info-dialog ref="infoDialog"></info-dialog>
        <message-notifier ref="notifier"></message-notifier>
    </div>
</template>


<script>

import MessageNotifier from '../../../shared/MessageNotifier.vue'
import DashboardActivationAttendanceDialog from './DashboardActivationAttendanceDialog.vue'
import DashboardActivationAttendanceReportDownloadDialog from './DashboardActivationAttendanceReportDownloadDialog.vue'
import ShiftUpdateStatusDialog from './ShiftUpdateStatusDialog.vue'
import TimesheetShiftInfoDialog from '../../project/timesheet/TimesheetShiftInfoDialog.vue'

export default {
    name: 'DashboardActivation',
    components: {
        messageNotifier: MessageNotifier,
        attendanceDialog: DashboardActivationAttendanceDialog,
        downloadDialog: DashboardActivationAttendanceReportDownloadDialog,
        statusDialog: ShiftUpdateStatusDialog,
        infoDialog: TimesheetShiftInfoDialog,
    },
    data() {
        return {
            tab: null,
            loading: true,
            filters: {
				project: '',
			},
            permissions: {
                can_list: false,
                can_edit: false
            },
			selectedIndex: -1,
            object: {},
            projects: [],
            items: [],
            headers: [],
            pagination: {},
        }
    },
    computed: {
        statusDialog() {
            return this.$refs.statusDialog
        },
        infoDialog() {
            return this.$refs.infoDialog
        },
        downloadDialog() {
            return this.$refs.downloadDialog
        },
        attendanceDialog() {
            return this.$refs.attendanceDialog
        },
        notifier() {
            return this.$refs.notifier
        }
    },
    mounted: function() {
        this.get()
    },
    methods: {
        get: function() {
            this.items = []
            this.$store.dispatch('epanel/dashboard/getActivationDashboard', this.filters).then((response) => {
                this.items = response.data.items
                this.headers = response.data.headers
                this.pagination = response.data.pagination
                this.projects = this.projects.length === 0?response.data.projects:this.projects
                this.permissions = response.data.permissions
                this.object = response.data.object
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        changeTab: function(tab) {
            this.filters.project = this.projects[tab].id
            this.get()
        },
        getProjectText: function(item) {
            return item.name + "  (" + item.checked_shifts + "/" + item.total_shifts + ")"
        },
        getPromoterText: function(item) {
            return [item.promoter.name, item.promoter.mobile_number].join("<br>")
        },
        getShiftTimeText: function(item) {
            return [item.start_time[0], item.end_time[0]].join(' - ')
        },
        getStatusColor: function(status) {
            var statusColor = ''
            switch(status) {
                case 'R': statusColor = 'light-blue white--text'; break;
                case 'CL': statusColor = 'red white--text'; break;
                case 'CI':
                case 'BO': statusColor = 'light-green white--text'; break;
                case 'BI': statusColor = 'indigo white--text'; break;
                case 'CO': statusColor = 'primary'; break;
            }

            return statusColor
        },
        openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        openStatusDialog: function(item, index, status) {
            if(item.can_ci || item.can_co || item.can_bi || item.can_bo){
                this.selectedIndex = index
                this.statusDialog.updateObject({ shift: item.id, status: status})
                this.statusDialog.open()
            }
        },
        openAttendanceDialog: function() {
            this.attendanceDialog.updateObject(this.object)
            this.attendanceDialog.updateItems(this.items)
            this.attendanceDialog.open()
        },
        openInfoDialog: function(item) {
            this.infoDialog.updateObject({campaign: item.campaign.value, shift: item.id})
            this.infoDialog.open()
        },
        openDownloadDialog: function() {
            const project = this.projects[this.tab]
            this.downloadDialog.updateObject({ project: project.id })
            this.downloadDialog.open()
        },
        updateItem: function(item) {
            this.$set(this.items, this.selectedIndex, item.object)
            this.openNotifier(item.message)
        },
        salesReportRemind: function(item){
            let text = "Hi, we noticed that you haven't submit your sales report, kindly submit your sales report in before 23:00, thanks."
            let url = "https://api.whatsapp.com/send?phone=+65"+item+"&text="+text
            window.open(url, '_blank');
        },
        chatWithPromoter: function(item) {
            let promoter_name = item.promoter.name;
            let promoter_mobile_number = item.promoter.mobile_number
            let outlet = item.outlet;
            let check_in = item.status.text
            let is_attendance = item.attendance_image;
            let start_time = new Date(item.start_time[1]);
            let end_time = new Date(item.end_time[1]);

            let report_timeline = new Date;
            report_timeline.setHours(23, 0 , 0); //11pm

            let project_managers = item.campaign.pm;
            let current_time = new Date();

            //time handling
            let one_hour_before = new Date(item.start_time[1]);
            let one_hour_before_job_raw = one_hour_before.setMinutes(one_hour_before.getMinutes() - 60);
            let one_hour_before_job = new Date(one_hour_before_job_raw);

            let fifteen_mins_before = new Date(item.start_time[1]);
            let fifteen_mins_before_job_raw = fifteen_mins_before.setMinutes(fifteen_mins_before.getMinutes() - 15);
            let fifteen_mins_before_job = new Date(fifteen_mins_before_job_raw);

            let thirty_mins_before_end_shift = new Date(item.end_time[1]);
            let thirty_mins_before_job_end_raw = thirty_mins_before_end_shift.setMinutes(thirty_mins_before_end_shift.getMinutes() - 30);
            let thirty_mins_before_end_job = new Date(thirty_mins_before_job_end_raw);

            //report submit section
            let thirty_mins_before_report = new Date()
            thirty_mins_before_report.setHours(23, 0 , 0);
            let thirty_mins_before_report_raw = thirty_mins_before_report.setMinutes(thirty_mins_before_report.getMinutes() - 30);
            let thirty_mins_before_report_submit = new Date(thirty_mins_before_report_raw);

            // whatsapp template message conditioning
            if(check_in === 'Checked-In') {
                if (is_attendance === false) {
                    let text = `Dear ${promoter_name}, thank you for clocking in for work on time! Please remember to upload photos of yourself in your designated work area before the work start.`;

                    let url = "https://api.whatsapp.com/send?phone=65"+promoter_mobile_number+"&text="+text
                    window.open(url, '_blank');
                } else {
                    if (current_time >= thirty_mins_before_end_job) {
                        let text = `Dear ${promoter_name}, you are ${this.timeDiff(current_time, end_time)} minutes away from ending your shift (unless extenting of hours is required if you are late). Please remember to clock in and out of break if you have not done so yet. Do remember to clock out of shift when your work ended! \n\nYou are also reminded to finish your sales report latest by 11pm today after you clock out in order to successfully complete your shift work with us to get paid`;

                        let url = "https://api.whatsapp.com/send?phone=65"+promoter_mobile_number+"&text="+encodeURIComponent(text)
                        window.open(url, '_blank');
                    }
                }          
            } if(check_in === 'Checked-Out') {
                if(current_time >= thirty_mins_before_report_submit) {
                    let text = `Dear ${promoter_name}, you are receiving this text because you have not submitted your sales report and complete your shift yet. You have ${this.timeDiff(current_time, report_timeline)} minutes left to finish the report before a $10 penalty will be enforced for incompletion of shift. `

                    let url = "https://api.whatsapp.com/send?phone=65"+promoter_mobile_number+"&text="+text
                    window.open(url, '_blank');
                }
            } else {
                if(current_time >= one_hour_before_job && current_time < fifteen_mins_before_job) {
                    let text = `Dear ${promoter_name}, please be reminded that you are ${this.timeDiff(current_time, start_time)} minutes away from your shift at ${outlet} that starts at ${start_time}. Please remember to clock in and submit your photo via Blink once you are at the outlet. Do note that punctuality is very important and pay might be deducted if you are late without a valid reason.`
                    
                    let url = "https://api.whatsapp.com/send?phone=65"+promoter_mobile_number+"&text="+text
                    window.open(url, '_blank');
                } else if(current_time >= fifteen_mins_before_job && current_time <= start_time) {
                    let text = `Dear ${promoter_name}, please be reminded that you are ${this.timeDiff(current_time, start_time)} minutes away from your shift at ${outlet} that starts at ${start_time}. Please remember to clock in and submit your photo via Blink once you are at the outlet. Do note that punctuality is very important and pay might be deducted if you are late without a valid reason. \n\nPlease contact your IC ${project_managers[0].full_name}, at wa.me/65${project_managers[0].mobile_number} if you are unable to reach on time and please share why so that we can update our client. `
                    
                    let url = "https://api.whatsapp.com/send?phone=65"+promoter_mobile_number+"&text="+encodeURIComponent(text)
                    window.open(url, '_blank');
                } else {
                    let url = "https://api.whatsapp.com/send?phone=65"+promoter_mobile_number
                    window.open(url, '_blank');
                }
            }                         
        },
        timeDiff: function(now, job_start) {
            let time_diff = (job_start.getTime() - now.getTime()) / 1000;
            time_diff /= 60;
            return Math.abs(Math.round(time_diff));
        }
    },
    watch: {
        tab: function(newValue, oldValue){
            if(Number.isInteger(oldValue)){
                this.changeTab(newValue)
            }
        }
    }
}
</script>


<style scoped>

/deep/ .v-toolbar__content {
    padding: 4px 0px !important;
}

</style>