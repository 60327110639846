<template>
	<v-dialog persistent max-width="764px" ref="statusDialog" v-model="dialog">
		<v-card>
			<v-card-title class="pl-4 pr-4">
				<span class="text-h5" v-text="getTitleText()"></span>
			</v-card-title>
			<v-card-text class="pl-4 pr-4">
                <v-form lazy-validation ref="form" v-model="valid">
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="errors && errors.message">
                            <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-alert dense border="left" type="warning" class="mb-0" v-html="getWarningText()"></v-alert>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-textarea rows="5" label="Remarks" :rules="rules.remarks" :error-messages="errors.remarks" v-model="object.remarks"></v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
			</v-card-text>
			<v-card-actions class="pl-4 pr-4">
				<v-spacer></v-spacer>
				<v-btn text @click="close">Let me think about it</v-btn>
				<v-btn text color="primary" :loading="loading" @click="save">Yes, do it</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>


<script>

export default {
	name: 'ShiftUpdateStatusDialog',
	data() {
		return {
			dialog: false,
			loading: false,
			valid: true,
			object: {
				status: '',
				shift: '',
				remarks: ''
			},
            rules: {
                remarks: [
                    (value) => !!value || 'Remarks is required'
                ]
            },
            errors: {}
		}
	},
	computed: {
		statusDialog() {
			return this.$refs.statusDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
        close: function() {
            this.dialog = false
            this.loading = false
            this.form.resetValidation()
            this.object = { status: '', shift: '', remarks: '' }

        },
        updateObject: function(item) {
            this.object = item
        },
		save: function() {
            if(this.form.validate()){
                this.loading = true
                this.$store.dispatch('epanel/dashboard/updateActivationShift', this.object).then((response) => {
                    this.$emit('updated', {object: response.data.object, message: response.message})
                    this.close()
                }).catch((errors) => {
                    this.errors = errors.data?errors.data.errors:errors
                    this.loading = false
                })
            }
		},
		getTitleText: function() {
            const titles = { 'ci': 'Shift Check-in Confirmation Dialog', 'bi': 'Shift Break-in Confirmation Dialog',
                             'bo': 'Shift Break-out Confirmation Dialog', 'co': 'Shift Check-out Confirmation Dialog'}
            return ['ci', 'bi', 'bo', 'co'].indexOf(this.object.status) > -1?titles[this.object.status]:'Shift Status Confirmation Dialog'
        },
        getWarningText: function() {
            const texts = { 'ci': 'Are you sure that you want to help this promoter to check in at this outlet?',
                            'bi': 'Are you sure that you want to help this promoter to break in at this outlet?',
                            'bo': 'Are you sure that you want to help this promoter to break out at this outlet?',
                            'co': 'Are you sure that you want to help this promoter to check out at this outlet?'}
            return ['ci', 'bi', 'bo', 'co'].indexOf(this.object.status) > -1?texts[this.object.status]:'Are you sure?'
        }
	}
}

</script>