<template>
	<div>
        <h2 class="text-h2 text-sm-h4 mb-3">Good Afternoon, <span v-text="auth.full_name"></span></h2>
        <v-alert type="info" class="alert" @click="go" v-if="object.has_new_applications">System has new promoter application. Click here to review them.</v-alert>
        <dashboard-activation></dashboard-activation>
        <br>
        <br>
       <kiv-promoter></kiv-promoter>
	</div>
</template>


<script>

import { getLocalStorage } from '@/utils/auth'
import DashboardActivation from './activation/DashboardActivation.vue'
import KivPromoter from './kivPromoter/KivPromoter.vue'
import Cookies from 'js-cookie'

export default {
	name: 'DashboardHome',
	components: {
        dashboardActivation: DashboardActivation,
        KivPromoter: KivPromoter
	},
    data() {
        return {
            auth: {
                full_name: ''
            },
            object: {
                has_new_applications: false,
            },
            items: [],
            headers: [],
            pagination: {}
        }
    },
    mounted: function() {
        this.check_accessToken_validity()
        this.getAuthObject()
        this.get()
    },
    methods: {
        get: function() {
            this.items = []
            this.$store.dispatch('epanel/dashboard/getDashboard', this.filters).then((response) => {
                this.items = response.data.items
                this.headers = response.data.headers
                this.pagination = response.data.pagination
                this.object = response.data.object
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        getAuthObject: function(){
            if(this.$store.getters.auth.full_name){
                this.auth = this.$store.getters.auth
            }else{
                let user = getLocalStorage('blink-data', 'user')
                if(user){
                    this.auth = user
                }
            }
        },
        go: function() {
            this.$router.push({path: '/epanel/dashboard/application'})
        },
        check_accessToken_validity: function() {
            let localstorage_data = JSON.parse(localStorage.getItem("blink-data"));
            let token_expiry_time = new Date(localstorage_data.user.expiry_time * 1000);
            let current_time = new Date()

            if(current_time > token_expiry_time) {
                this.delete_accessToken()
                this.$router.push('/epanel/login')
            }
        },
        delete_accessToken: function() {
            Cookies.remove('access-token', 'value', { path: '/' })
        }
    }
}

</script>


<style scoped>

.alert:hover {
    cursor: pointer !important;
}

</style>