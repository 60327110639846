<template>
    <div>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0 d-flex justify-space-between">
                    <h2 class="font-weight-regular">List of KIV promoter</h2>
                    <v-tooltip bottom><template v-slot:activator="{ on, attrs }"><v-btn icon v-bind="attrs" v-on="on" :loading="loading" @click="get"><v-icon>mdi-refresh</v-icon></v-btn></template><span>Refresh</span></v-tooltip>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="projects.length === 0">
                    <p class="mb-0">No promoter is in KIV</p>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="projects.length > 0">
                    <v-sheet color="white" elevation="0">
                        <v-tabs show-arrows color="primary" v-model="tab">
                            <v-tab :key="index" v-text="getProjectText(item)" v-for="(item, index) in projects"></v-tab>
                        </v-tabs>
                        <v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
                            <template v-slot:item='{ item }'>
                                <tr>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="item.campaign.text"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="item.outlet"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-html="getPromoterText(item)"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="item.work_date"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="getShiftTimeText(item)"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="item.break_time"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0">
                                        <v-chip label small :color="getPromoterStatusColor(item.promoter_status)"><span v-text="item.promoter_status"></span></v-chip>
                                    </td>

                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0">
                                        <v-btn x-small :ripple="false" color="primary" class="elevation-0" @click="changePromoterStatus(item)">Switch Promoter Status</v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-sheet>
                </v-col>
            </v-row>
        </template>
    </div>
</template>


<script>


export default {
    name: 'KivPromoter',
    components: {
    },
    data() {
        return {
            tab: null,
            loading: true,
            filters: {
				project: '',
			},
            permissions: {
                can_list: false,
                can_edit: false
            },
            object: {},
            projects: [],
            items: [],
            headers: [],
            pagination: {},
        }
    },
    mounted: function() {
        this.get()
    },
    methods: {
        get: function() {
            this.items = []
            this.$store.dispatch('epanel/dashboard/getKivPromoterDashboard', this.filters).then((response) => {
                this.items = response.data.items
                this.headers = response.data.headers
                this.pagination = response.data.pagination
                this.projects = this.projects.length === 0?response.data.projects:this.projects
                this.permissions = response.data.permissions
                this.object = response.data.object
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        changeTab: function(tab) {
            this.filters.project = this.projects[tab].id
            this.get()
        },
        getProjectText: function(item) {
            return item.name 
        },
        getPromoterText: function(item) {
            return [item.promoter.name, item.promoter.mobile_number].join("<br>")
        },
        getShiftTimeText: function(item) {
            return [item.start_time[0], item.end_time[0]].join(' - ')
        },
        getPromoterStatusColor: function(item) {
            const colors = {"KIV": "", "C": "light-blue white--text"}
            return colors[item.value]
        },
        changePromoterStatus: function(item){
            this.loading = true
            var object = {shift: item.id, promoter_status: item.promoter_status}
            this.$store.dispatch('epanel/dashboard/updateKivPromoterDashboard', object).then((response) => {
                this.$emit('updated', {object: response.data.object, message: response.message})
                this.get()
            }).catch((errors) => {
                this.errors = errors.data?errors.data.errors:errors
                this.loading = false
            })
            
        }
    },
    watch: {
        tab: function(newValue, oldValue){
            if(Number.isInteger(oldValue)){
                this.changeTab(newValue)
            }
        }
    }
}
</script>


<style scoped>

/deep/ .v-toolbar__content {
    padding: 4px 0px !important;
}

</style>