<template>
	<v-dialog persistent max-width="764px" ref="attendanceDialog" v-model="dialog">
		<v-card>
			<v-card-title class="pl-4 pr-4">
				<span class="text-h5 mb-3">Promoter Attendance Summary</span>
                <v-spacer></v-spacer>
                <v-switch v-model="object.btn" @click='cleanItems'>Show time</v-switch>
			</v-card-title>
			<v-card-text class="pl-4 pr-4">
                <p v-html="message"></p>
			</v-card-text>
			<v-card-actions class="pl-4 pr-4">
				<v-spacer></v-spacer>
				<v-btn text @click="close">Close</v-btn>
                <copy-to-clipboard :text="copyMessage" @copy="save">
                    <v-btn text color="primary">Copy</v-btn>
                </copy-to-clipboard>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>


<script>

import CopyToClipboard from 'vue-copy-to-clipboard'

export default {
	name: 'DashboardActivationAttendanceDialog',
	components: {
        CopyToClipboard
	},
	data() {
		return {
			dialog: false,
			loading: false,
			object: {},
			items: [],
			message: '',
			copyMessage: '',
            btn: ''
		}
	},
	computed: {
		attendanceDialog() {
			return this.$refs.attendanceDialog
		},
        clipboard() {
            return this.$refs.clipboard
        }
	},
	methods: {
		open: function() {
			this.dialog = true
			this.cleanItems()
		},
        close: function() {
            this.dialog = false
            this.loading = false
            this.object = {}
            this.items = []
			this.message = ''
			this.copyMessage = ''
        },
        updateObject: function(item) {
            this.object = item
        },
        updateItems: function(items) {
            this.items = items
        },
        cleanItems: function() {
            var items = {}
            this.items.forEach((item) => {
                const campaign = item.campaign.text, start_time = item.start_time
                if(!items[campaign]){
                    items[campaign] = {}
                }

                if(!items[campaign][start_time]){
                    items[campaign][start_time] = []
                }
                if(this.object.btn==true){
                    this.message=''
                    const message = [item.outlet, item.activities.ci?'Yes':'No'].join(' - ') + " ("+item.ci_time+")"
                    items[campaign][item.start_time].push(message)
                }
                else {
                    this.message=''
                    const message = [item.outlet, item.activities.ci?'Yes':'No'].join(' - ')
                    items[campaign][item.start_time].push(message)
                }
            })

            this.message += ('Date of Campaign: ' + this.object.work_date + '<br>')

            for(var key in items){
                this.message += ('Campaign Name: ' + key + '<br>')
                for(var skey in items[key]){
                    this.message += ('Start Time: ' + skey.split(',')[0] + '<br>')
                    this.message += items[key][skey].join('<br>')
                    this.message += '<br><br>'
                }
            }

            this.copyMessage = this.message.replaceAll('<br>', '\n')
        },
        save: function() {
            this.$emit('copied', 'Promoter attendance summary is copied into clipboard')
		},
	}
}

</script>